<template>
    <!-- only supporting one level(non nested) object for now -->
    <div class="BasicFilterInput">
        <PopoverEntityNumberFilter
            v-if="['number', 'integer'].includes(type)"
            class="BasicFilterInput__numberField "
            data-test-id="BasicFilterInput__numberField"
            :value="value"
            :type="type"
            :label="label"
            :placeholder="placeholder"
            @input="inputFilter"
            @scrollTo="emitScrollDistance"
        />
        <FormElement
            v-else-if="type === 'boolean'"
            ref="dropdownRef"
            class="BasicFilterInput__booleanField"
            data-test-id="BasicFilterInput__booleanField"
            :label="label"
        >
            <FormInput>
                <Dropdown
                    :options="booleanOptions"
                    :selected="convertBoolFilterToOptions(value)"
                    @input="onBooleanFieldChange"
                    @open="scrollToElement('dropdownRef')"
                />
            </FormInput>
        </FormElement>
        <FormElement
            v-else
            class="BasicFilterInput__textField"
            data-test-id="BasicFilterInput__textField"
            :label="label"
        >
            <FormInput>
                <input
                    :value="value"
                    :type="type"
                    :placeholder="placeholder"
                    @input="inputEventFilter"
                >
            </FormInput>
        </FormElement>
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue';
    import { Vue } from '@evidentid/vue-property-decorator';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import PopoverEntityNumberFilter
        from '@/modules/entity-filtering/components/EntityFiltersPopover/PopoverEntityNumberFilter.vue';
    import { Dropdown } from '@evidentid/dashboard-commons/components/Dropdown';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';

    export default Vue.extend({
        name: 'BasicFilterInput',
        components: {
            Dropdown,
            FormElement,
            FormInput,
            PopoverEntityNumberFilter,
        },
        props: {
            type: {
                type: String as PropType<'text' | 'number' | 'integer' | 'boolean'>,
                default: 'text',
            },
            label: {
                type: String as PropType<string>,
                default: '',
            },
            placeholder: {
                type: String as PropType<string>,
                default: '',
            },
            value: {
                type: [ String, Number, Object ] as PropType<string | number | object | null | undefined>,
                default: undefined,
            },
        },
        data() {
            return {
                booleanOptions: [
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' },
                ] as DropdownOption[],
            };
        },
        methods: {
            emitScrollDistance(distance: number): void {
                this.$emit('scroll-to', distance);
            },
            inputFilter(value: string | null): void {
                this.$emit('input', value);
            },
            inputEventFilter(inputEvent: InputEvent): void {
                this.inputFilter((inputEvent.target as HTMLInputElement).value);
            },
            onBooleanFieldChange(option: DropdownOption): void {
                this.inputFilter(option.value);
            },
            convertBoolFilterToOptions(value: 'true' | 'false'): DropdownOption[] {
                return value ? [ { label: value === 'true' ? 'Yes' : 'No', value } ] : [];
            },
            scrollToElement(refName: string): void {
                // refs returns array when it's within v-for
                const formElementList = this.$refs[refName] as FormElement;
                if (formElementList) {
                    this.$nextTick(() => {
                        this.emitScrollDistance((formElementList.$el as HTMLElement).offsetTop);
                    });
                }
            },
        },
    });
</script>
