<template>
    <FormElement :label-for="id" :label="form.schema.title" :required="required">
        <template #requiredIndicator>
            {{ translate('requiredLabel') }}
        </template>
        <FormInput :invalid="hasError" force-error-slot>
            <Dropdown
                :id="id"
                :disabled="disabled"
                :options="[...booleanOptions]"
                :selected="convertBoolToOptions(value)"
                placeholder=""
                @input="onBooleanFieldChange($event)"
            />
            <template v-if="error" #errorMessage>
                {{ error }}
            </template>
        </FormInput>
    </FormElement>
</template>

<script lang="ts">
    import { Component } from '@evidentid/vue-property-decorator';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaBoolean } from '@evidentid/json-schema/interfaces/JsonSchema';
    import AbstractJsonSchemaForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/elements/AbstractJsonSchemaForm';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import Dropdown from '@evidentid/dashboard-commons/components/Dropdown/Dropdown.vue';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';

    type JsonFormBoolean = JsonFormBase<JsonSchemaBoolean>;

    @Component({
        components: { Dropdown, FormInput, FormElement },
        inheritAttrs: false,
    })
    export default class EntityDetailsBooleanForm extends AbstractJsonSchemaForm<JsonFormBoolean, boolean> {
        private booleanOptions: DropdownOption[] = [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
        ];

        private convertBoolToOptions(value: boolean | undefined): DropdownOption[] {
            return value === undefined ? [] : [ { label: value ? 'Yes' : 'No', value: Boolean(value) } ];
        }

        private onBooleanFieldChange(option: DropdownOption): void {
            this.$emit('input', this.form.getValue(option.value));
            this.$emit('change', this.form.getValue(option.value));
        }
    }
</script>
