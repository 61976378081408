<template>
    <div class="DecisioningCriteria">
        <Page :loading="isLoading" title="Decisioning" non-destructive-on-loading>
            <CreateEditRiskProfile
                :custom-properties-status="customPropertiesStatus"
                :loading-custom-properties="loadingCustomProperties"
                :risk-profile="localRiskProfile"
                :requirement-types="requirementTypes"
                :requirement-models="getRequirementModelsWithCountryLabel"
                :criteria-templates="templatesStatus.list"
                :criteria="localCriteria"
                :original-criteria="criteriaCopy"
                :current-requirement-type="requirementType"
                :loading-requirement-types-and-countries="loadingCoverageTypesAndCountries"
                :collateral-enabled="isCollateralEnabled"
                @load-custom-properties="loadCustomProperties"
                @selectRequirementType="selectCoverageType"
                @deselectRequirementType="deselectCoverageType"
                @profileInput="onRiskProfileInput"
                @criteriaInput="onCriteriaInput"
                @save="save"
                @revert-risk-profile-changes="revertChanges"
            />
        </Page>
        <RiskProfileSetupModal
            v-if="showRiskProfileSetupModal && !isLoading"
            :country-code-list="countryCodeList"
            :categorized-enum-labels="categorizedEnumLabels"
            :collateral-enabled="isCollateralEnabled"
            confirm-only
            @confirm="confirmCountryCode"
            @abort="abortCountryCodeConfirmation"
        />
        <ConfirmNavigationOnDirtyState v-if="!isLoading" :dirty="modified" ignore-query />
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Watch } from '@evidentid/vue-property-decorator';
    import noop from 'lodash/noop';
    import omit from 'lodash/omit';
    import isEqual from 'lodash/isEqual';
    import cloneDeep from 'lodash/cloneDeep';
    import Page from '@/layouts/Page.vue';
    import {
        ConfirmNavigationOnDirtyState,
    } from '@evidentid/dashboard-commons/components/ConfirmNavigationOnDirtyState';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { Tab, Tabs } from '@evidentid/dashboard-commons/components/Tabs';
    import { createEmptyRequestsConfigStatus } from '@/modules/dashboard/vuex/dashboard';
    import RiskProfiles
        from '@/modules/decisioning-criteria/components/RiskProfiles/RiskProfiles.vue';
    import CreateEditRiskProfile
        from '@/modules/decisioning-criteria/components/CreateEditRiskProfile/CreateEditRiskProfile.vue';
    import { TprmRequirementType } from '@evidentid/tprm-portal-lib/models/entity-details';
    import {
        RiskProfileStatus,
        CriteriaStatus,
        CriterionTemplatesStatus,
        RequirementModelsStatus,
        RequirementTypesPerCountryStatus,
    } from '@/modules/decisioning-criteria/vuex';
    import { convertCriteriaChangesToPatches } from '@/modules/decisioning-criteria/utils/riskProfileCriteria';
    import { syncCriteriaWithTemplates } from '@/modules/decisioning-criteria/utils/syncCriteriaWithTemplates';
    import {
        syncAdditionalInsuredCriteriaWithTemplate,
    } from '@/modules/decisioning-criteria/utils/syncAdditionalInsuredCriteriaWithTemplate';
    import RiskProfileSetupModal
        from '@/modules/decisioning-criteria/components/RiskProfileSetupModal/RiskProfileSetupModal.vue';
    import { ConfirmEventPayload } from '@/modules/decisioning-criteria/models/ConfirmEventPayload.model';
    import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
    import {
        getRequirementTypeLabelByCountry,
    } from '@/utils/get-requirement-type-label-by-country/getRequirementTypeLabelByCountry';
    import {
        RISK_PROFILE_AUTO_ADD_UMBRELLA_CREATE_PROFILE_ID,
        assignCreateGroupAutoUmbrellaStatusId,
        confirmDisablingRiskProfileAutoAddUmbrella,
    } from '@/modules/decisioning-criteria/utils/autoAddUmbrella';
    import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';
    import { Criterion, CriterionInput } from '@evidentid/tprm-portal-lib/models/decisioning/Criterion.model';
    import { TprmRequestsConfig } from '@evidentid/tprm-portal-lib/models/common/TprmRequestsConfig.model';
    import {
        TprmRequirementModel,
    } from '@evidentid/tprm-portal-lib/models/entity-details/TprmRequirementModel.model';
    import {
        RiskProfile,
        RiskProfileInput,
        RiskProfileType,
    } from '@evidentid/tprm-portal-lib/models/decisioning/RiskProfile.model';

    @Component({
        components: {
            ConfirmNavigationOnDirtyState,
            CreateEditRiskProfile,
            Page,
            RiskProfileSetupModal,
            RiskProfiles,
            Tab,
            Tabs,
        },
    })
    export default class RiskProfileView extends Vue {
        private lastRpName!: string;
        private localRiskProfile: RiskProfileInput | RiskProfile =
            this.getDefaultEmptyRiskProfile();
        private isRiskProfileNameInputInModal: boolean = false;
        private syncedCriteria: (Criterion | CriterionInput)[] = [];
        private localCriteria: (Criterion | CriterionInput)[] = [];
        private forcedNavigate: boolean = false;
        private riskProfileCopy = this.getDefaultEmptyRiskProfile();
        private criteriaCopy: (Criterion | CriterionInput)[] = [];

        private get rpName(): string {
            return this.$rp.current!;
        }

        private get showRiskProfileSetupModal(): boolean {
            const { countryCode, displayName } = this.currentRiskProfileStatus.data || {};
            const hasCountryCode = countryCode || this.localRiskProfile.countryCode;
            const hasRiskProfileName = displayName || this.isRiskProfileNameInputInModal;
            return !hasCountryCode || !hasRiskProfileName;
        }

        private get riskProfileId(): string {
            return this.$route.params.id;
        }

        private get requirementType(): string | null {
            const currentCoverageType = this.$route.query?.requirement || null;
            return currentCoverageType && typeof currentCoverageType === 'string' ? currentCoverageType : null;
        }

        private get riskProfile(): RiskProfileInput | RiskProfile {
            return this.currentRiskProfileStatus.data || this.getDefaultEmptyRiskProfile();
        }

        private get criteria(): Criterion[] {
            const criteria = this.riskProfileId ? this.criteriaStatus.criteria[this.riskProfileId] || [] : [];
            const templateAdditionalInsured = this.templatesStatus.list
                .find((template) => template.field === 'coverage.endorsements.additionalInsured');

            if (!templateAdditionalInsured) {
                return criteria;
            }

            return syncAdditionalInsuredCriteriaWithTemplate(criteria, templateAdditionalInsured);
        }

        private get currentRiskProfileStatus(): RiskProfileStatus {
            return this.$store.state.decisioningCriteria.currentRiskProfile[this.rpName] || {
                status: OperationStatus.uninitialized,
                data: null,
            };
        }

        private get criteriaStatus(): CriteriaStatus {
            return this.$store.state.decisioningCriteria.criteria[this.rpName] || {
                status: OperationStatus.uninitialized,
                criteria: {},
            };
        }

        private get templatesStatus(): CriterionTemplatesStatus {
            return this.$store.state.decisioningCriteria.criterionTemplates[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get requirementTypesPerCountryStatus(): RequirementTypesPerCountryStatus {
            return this.$store.state.decisioningCriteria.requirementTypesPerCountry[this.rpName] || {
                status: OperationStatus.uninitialized,
                data: {},
            };
        }

        private get creatingRiskProfileStatus(): OperationStatus {
            return this.$store.state.decisioningCriteria.creatingRiskProfileStatus[this.rpName] ||
                OperationStatus.uninitialized;
        }

        private get creatingCriteriaStatus(): OperationStatus {
            return this.$store.state.decisioningCriteria.creatingCriteriaStatus[this.rpName] ||
                OperationStatus.uninitialized;
        }

        private get patchingCriteriaStatus(): OperationStatus {
            return this.$store.state.decisioningCriteria.patchingCriteriaStatus[this.rpName] ||
                OperationStatus.uninitialized;
        }

        private get updatingRiskProfileStatus(): OperationStatus {
            return this.$store.state.decisioningCriteria.updatingRiskProfileStatus[this.rpName] ||
                OperationStatus.uninitialized;
        }

        private get requirementModelsStatus(): RequirementModelsStatus {
            return this.$store.state.decisioningCriteria.requirementModelsStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get loadingCoverageTypesAndCountries(): boolean {
            return this.requirementTypesPerCountryStatus.status === OperationStatus.loading;
        }

        private get loadingCurrentRiskProfile(): boolean {
            return this.currentRiskProfileStatus.status === OperationStatus.loading;
        }

        private get loadingCriteria(): boolean {
            return this.criteriaStatus.status === OperationStatus.loading;
        }

        private get loadingTemplates(): boolean {
            return this.templatesStatus.status === OperationStatus.loading;
        }

        private get savingRiskProfile(): boolean {
            return this.creatingRiskProfileStatus === OperationStatus.loading ||
                this.updatingRiskProfileStatus === OperationStatus.loading;
        }

        private get savingCriteria(): boolean {
            return this.creatingCriteriaStatus === OperationStatus.loading ||
                this.patchingCriteriaStatus === OperationStatus.loading;
        }

        private get isLoadingRequirementModels(): boolean {
            return this.requirementModelsStatus.status === OperationStatus.loading;
        }

        private get isLoading(): boolean {
            return this.loadingCoverageTypesAndCountries ||
                this.loadingTemplates ||
                this.loadingCurrentRiskProfile ||
                this.loadingCriteria ||
                this.savingRiskProfile ||
                this.savingCriteria ||
                this.isLoadingRequirementModels;
        }

        private get customPropertiesStatus(): { status: OperationStatus, list: CustomProperty[] } {
            return this.$store.state.dashboard.customProperties[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get loadingCustomProperties(): boolean {
            return this.customPropertiesStatus.status === OperationStatus.loading;
        }

        private get countryCode(): string {
            return this.currentRiskProfileStatus.data?.countryCode
                || (this.localRiskProfile as RiskProfileInput).countryCode
                || 'US';
        }

        private get countryCodeList(): string[] {
            return Object.keys(this.requirementTypesPerCountryStatus.data);
        }

        private get categorizedEnumLabels(): CategorizedEnumLabels {
            return this.$store.state.dashboard.categorizedEnumLabels;
        }

        private get requirementTypes(): TprmRequirementType[] {
            return this.requirementTypesPerCountryStatus.data[this.countryCode]?.coverageTypes || [];
        }

        private get modified(): boolean {
            return this.forcedNavigate
                ? false
                : !isEqual(this.riskProfile, this.localRiskProfile) ||
                    !isEqual(this.syncedCriteria, this.localCriteria);
        }

        private get getRequirementModelsWithCountryLabel(): TprmRequirementModel[] {
            const countryCode = this.localRiskProfile.countryCode;
            return this.requirementModelsStatus.list.map((model) => {
                const label = getRequirementTypeLabelByCountry(
                    model.coverageType, countryCode, this.categorizedEnumLabels,
                );
                return { ...model, label };
            });
        }

        @Watch('$route')
        private onRouteChange(route: any, prevRoute: any): void {
            const isSwitchRp = route.params.id === prevRoute.params.id && route.params.rpId !== prevRoute.params.rpId;
            if (isSwitchRp) {
                this.clearPageData(prevRoute.params.rpId);
                this.$router.push({
                    name: 'decisioning',
                    params: {
                        rpId: route.params.rpId,
                    },
                }).catch((error) => {
                    if (error.message.includes('Navigation cancelled')) {
                        return;
                    }
                    throw error;
                });
            } else if (!isEqual(route.params, prevRoute.params)) {
                this.loadPageData();
            }
        }

        @Watch('$rp.current', { immediate: true })
        private handleRpChange(rpName: string, prevRpName: string) {
            // Save information about the last resource, which should be cleared during destroy
            this.lastRpName = rpName;
            if (prevRpName) {
                this.clearPageData(prevRpName);
                this.$router.push({
                    name: 'decisioning',
                    params: {
                        rpId: this.$route.params.rpId,
                    },
                });
            } else {
                this.loadPageData();
            }
        }

        @Watch('riskProfile', { immediate: true })
        private onRiskProfileChange(current: RiskProfile, prev: RiskProfile) {
            if (!isEqual(current, prev)) {
                this.localRiskProfile = { ...this.riskProfile };
                this.riskProfileCopy = cloneDeep(this.localRiskProfile);
            }
        }

        @Watch('criteria', { immediate: true })
        @Watch('templatesStatus.list', { immediate: true })
        private onCriteriaChange(current: Criterion[], prev: Criterion[]) {
            if (!isEqual(current, prev)) {
                // syncedCriteria to not trigger unsaved modal when we sync(due to data issue) as it's not user action
                this.syncedCriteria = syncCriteriaWithTemplates(this.criteria, this.templatesStatus.list);
                this.localCriteria = [ ...this.syncedCriteria ];
                this.criteriaCopy = cloneDeep(this.localCriteria);
            }
        }

        private async loadPageData(): Promise<void> {
            await this.loadCurrentRiskProfile();
            this.loadCriteria();
            this.loadTemplates();
            this.loadCoverageTypesAndCountries();
            this.loadCustomProperties();
            // FIXME: temporary flag, remove once all rp migrated to decisioning engine
            this.loadRequirementModels();
        }

        private async loadCurrentRiskProfile(): Promise<void> {
            if (!this.loadingCurrentRiskProfile && this.riskProfileId != null) {
                await this.$store.actions.decisioningCriteria.loadRiskProfile({
                    rpName: this.rpName,
                    id: this.riskProfileId,
                });
            }
        }

        private loadCriteria(): void {
            if (!this.loadingCriteria && this.riskProfileId != null) {
                this.$store.actions.decisioningCriteria.loadCriteria({
                    rpName: this.rpName,
                    riskProfileId: this.riskProfileId,
                });
            }
        }

        private loadTemplates(): void {
            if (!this.loadingTemplates && this.localRiskProfile?.countryCode) {
                this.$store.actions.decisioningCriteria.loadCriterionTemplates({
                    rpName: this.rpName,
                    countryCode: this.localRiskProfile.countryCode,
                });
            }
        }

        private loadCoverageTypesAndCountries(): void {
            if (!this.loadingCoverageTypesAndCountries) {
                this.$store.actions.decisioningCriteria.loadRequirementTypesPerCountry({ rpName: this.rpName });
            }
        }

        private loadCustomProperties() {
            if (!this.loadingCustomProperties) {
                this.$store.actions.dashboard.loadCustomProperties({ rpName: this.rpName });
            }
        }

        private async save(data: {
            riskProfile: RiskProfile | RiskProfileInput;
            criteria: CriterionInput[] | Criterion[];
        }): Promise<void> {
            // TODO: consider not to emit profile and criteria since it's updated on input. emit active or discovery
            const { riskProfile, criteria } = data;
            if ('id' in riskProfile && riskProfile.id) {
                await this.patchCriteria(
                    (riskProfile as RiskProfile).id,
                    criteria,
                );
                await this.updateRiskProfile(riskProfile as RiskProfile);
                await this.loadCurrentRiskProfile();
                this.loadCriteria();
                confirmDisablingRiskProfileAutoAddUmbrella(riskProfile.id);
            } else {
                await this.createRiskProfile(riskProfile as RiskProfileInput);
                await this.createCriteria(
                    this.currentRiskProfileStatus.data?.id || null,
                    criteria as CriterionInput[],
                );
                if (this.currentRiskProfileStatus.data?.id) {
                    // for auto add umbrella coverage type feature: confirm disabling auto add, and record to session
                    confirmDisablingRiskProfileAutoAddUmbrella(RISK_PROFILE_AUTO_ADD_UMBRELLA_CREATE_PROFILE_ID);
                    // carry over autoUmbrellaStatus in session storage since profile created
                    assignCreateGroupAutoUmbrellaStatusId(this.currentRiskProfileStatus.data.id);
                    // since create profile view does not associate with an id, the data to create profile is saved to a
                    // new profile thus at this navigation to the new profile will always have dirty state, thus reset
                    // dirty state before navigated to newly created profile
                    this.forcedNavigate = true;
                    this.$router.push({
                        name: 'decisioningRiskProfile',
                        params: {
                            rpId: this.$route.params.rpId,
                            id: this.currentRiskProfileStatus.data.id,
                        },
                    });
                }
            }
        }

        private async createRiskProfile(riskProfile: RiskProfileInput): Promise<void> {
            if (this.creatingRiskProfileStatus !== OperationStatus.loading) {
                await this.$store.actions.decisioningCriteria.createRiskProfile({
                    rpName: this.rpName,
                    riskProfile,
                });
            }
        }

        private async updateRiskProfile(riskProfile: RiskProfile): Promise<void> {
            if (this.updatingRiskProfileStatus !== OperationStatus.loading) {
                await this.$store.actions.decisioningCriteria.updateRiskProfile({
                    rpName: this.rpName,
                    riskProfile,
                });
            }
        }

        private async createCriteria(
            riskProfileId: string | null,
            criteria: CriterionInput[],
        ): Promise<void> {
            if (riskProfileId && this.creatingCriteriaStatus !== OperationStatus.loading) {
                await this.$store.actions.decisioningCriteria.createCriteria({
                    rpName: this.rpName,
                    riskProfileId,
                    criteria,
                });
            }
        }

        private async patchCriteria(
            riskProfileId: string,
            criteria: CriterionInput[] | Criterion[],
        ): Promise<void> {
            const patches = convertCriteriaChangesToPatches(this.criteria, criteria);
            if (this.patchingCriteriaStatus !== OperationStatus.loading) {
                await this.$store.actions.decisioningCriteria.patchCriteria({
                    rpName: this.rpName,
                    riskProfileId,
                    patches,
                });
            }
        }

        private confirmCountryCode({ countryCode, displayName, riskProfileType }: ConfirmEventPayload): void {
            this.isRiskProfileNameInputInModal = true;
            this.localRiskProfile = { ...this.localRiskProfile, countryCode, displayName, ccgType: riskProfileType };
            this.loadTemplates();
        }

        private abortCountryCodeConfirmation(): void {
            this.$router.push({
                name: 'decisioning',
                params: {
                    rpId: this.$route.params.rpId,
                },
            });
        }

        private selectCoverageType(requirementType: TprmRequirementType): void {
            this.$router.push({
                ...this.$route,
                query: { ...this.$route.query, requirement: requirementType },
            } as any).catch(noop);
        }

        private deselectCoverageType(): void {
            this.$router.push({
                ...this.$route,
                query: {
                    ...omit(this.$route.query, 'requirement'),
                },
            } as any).catch(noop);
        }

        private onRiskProfileInput(riskProfile: RiskProfile): void {
            this.localRiskProfile = { ...riskProfile };
        }

        private onCriteriaInput(criteria: (Criterion | CriterionInput)[]) {
            this.localCriteria = [ ...criteria ];
        }

        private clearPageData(rp?: string) {
            const rpName = rp || this.lastRpName;
            this.$store.actions.decisioningCriteria.clearCurrentRiskProfiles({ rpName });
            this.$store.actions.dashboard.clearRequirementTypeList({ rpName });
            this.$store.actions.decisioningCriteria.clearCreatingRiskProfileStatus({ rpName });
            this.$store.actions.decisioningCriteria.clearUpdatingRiskProfileStatus({ rpName });
            this.$store.actions.decisioningCriteria.clearCreatingCriteriaStatus({ rpName });
            this.$store.actions.decisioningCriteria.clearPatchingCriteriaStatus({ rpName });
            this.$store.actions.decisioningCriteria.clearRpCriteria({ rpName });
            this.$store.actions.decisioningCriteria.clearCriterionMessage({ rpName });
            this.$store.actions.dashboard.clearCustomPropertiesList({ rpName });
            this.$store.actions.decisioningCriteria.clearRequirementModelsStatus({ rpName: this.lastRpName });
        }

        private get isCollateralEnabled(): boolean {
            return this.requestsConfigStatus.data?.collateralsEvaluationEnabled || false;
        }

        private get requestsConfigStatus(): {
            status: OperationStatus;
            data: TprmRequestsConfig | null;
        } {
            return this.$store.state.dashboard.requestsConfig[this.rpName || ''] || createEmptyRequestsConfigStatus();
        }

        private get isLoadingRequestsConfig(): boolean {
            return this.requestsConfigStatus.status === OperationStatus.loading;
        }

        private loadRequirementModels() {
            if (!this.isLoadingRequirementModels) {
                this.$store.actions.decisioningCriteria.loadRequirementModels({ rpName: this.rpName || '' });
            }
        }

        private revertChanges(): void {
            this.localRiskProfile = this.riskProfileCopy;
            this.localCriteria = this.criteriaCopy;
        }

        private getDefaultEmptyRiskProfile(): RiskProfileInput {
            return {
                displayName: '',
                active: false,
                ccgType: RiskProfileType.default,
                numberOfCoverageTypes: null,
                numberOfInsuredsAssigned: null,
                assignmentRule: true,
                countryCode: null,
            };
        }

        private destroyed() {
            this.clearPageData();
        }
    }
</script>
