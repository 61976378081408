<template>
    <div class="Policy">
        <div class="Policy__headerBar" @click="toggle">
            <div class="Policy__requirementTypeLabel">
                <FontAwesomeIcon class="Policy__headerBarToggle" :icon="caretIcon" />
                <FontAwesomeIcon v-if="icon" class="Policy__headerBarIcon" :icon="icon" />
                <EidIcon
                    v-if="showRequirementTypeIcon"
                    class="Policy__requirementIcon"
                    :icon-src="requirementTypeIcon"
                    :svg-props="{color: evidentGreenColor}"
                />
                <span class="Policy__headerBarRequirementName">{{ requirementTypeLabel }}</span>
                <EvaluationRuleRequirementIcon v-if="isEvaluationRuleRequirement" />
            </div>
            <div v-if="showComplianceStatus" class="Policy__complianceStatus">
                <ComplianceStatusBadge
                    class="EntityDetails__headerStatusBadge"
                    :status="requirementDetails.complianceStatus"
                />
            </div>
            <ExceptionBadge
                v-if="isStatusOverridePresent"
                class="EvaluationError__exceptionBadge"
                :label="exceptionLabel"
                :exception-type="requirementDetails.complianceStatusMetadata.statusOverride.type"
                :until="exceptionUntil"
                :applied-to="requirementDetails.complianceStatusMetadata.statusOverride.level"
                :disable-popover="false"
            />
            <Expandable v-if="hasAnyAction" ref="actionMenu">
                <template #button>
                    <FontAwesomeIcon
                        :icon="faEllipsisH"
                        class="Policy__actionsButton"
                    />
                </template>

                <Menu v-if="isStatusOverridePresent" spaced>
                    <MenuLink
                        class="Policy__removeExceptionMenu"
                        :disabled="hasEntityLevelException"
                        @click="$emit('removeExceptions', [ requirementDetails.complianceStatusMetadata.statusOverride.id ])"
                    >
                        <template #label>
                            <div class="Policy__menuLink">
                                <span class="Policy__label">
                                    Remove Exception
                                </span>
                            </div>
                        </template>
                    </MenuLink>
                </Menu>
                <Menu v-else-if="requirementDetails.complianceStatus === 'NON_COMPLIANT'" spaced>
                    <MenuLink class="Policy__grantRequirementTypeExceptionMenu" @click="grantExceptionForRequirement">
                        <template #label>
                            <div class="Policy__menuLink">
                                <span class="Policy__label">
                                    Grant exceptions for entire requirement
                                </span>
                                <div class="Policy__menuBody">
                                    <strong>All current and future non-compliant criteria</strong>
                                    <span> will be waived as compliant. This can be undone.</span>
                                </div>
                            </div>
                        </template>
                    </MenuLink>
                    <MenuLink
                        v-if="requirement"
                        class="Policy__grantCriteriaExceptionsMenu"
                        @click="grantExceptionForNonCompliantCriteria"
                    >
                        <template #label>
                            <div class="Policy__menuLink">
                                <span class="Policy__label">
                                    Grant exceptions for non-compliant criteria only
                                </span>
                                <div class="Policy__menuBody">
                                    <strong>Current non-compliant criteria</strong>
                                    <span> will be waived as compliant. This can be undone.</span>
                                </div>
                            </div>
                        </template>
                    </MenuLink>
                </Menu>
            </Expandable>
            <DocumentDataOnlyBadge v-if="isShowingDataOnlyBadge" />
            <DocumentViewButton v-else-if="isShowingDocumentViewButton" class="mr-3" @click.stop="showDocument" />
        </div>
        <div v-if="opened">
            <NoPolicy
                v-if="noPolicy"
                :requirement-details="requirementDetails"
                :requirement-processing="requirementProcessing"
                @grant-exception-for-requirement="grantExceptionForRequirement"
            />
            <PolicyInfo
                v-else-if="isInsuranceRequirements"
                :requirement="requirement"
                :requirement-model="requirementModel"
                :evaluation-errors="evaluationErrors"
                :all-requirements="allRequirements"
                :evaluation-results="requirementDetails.evaluationResults"
                :collateral-entities="collateralEntities"
                :non-extraction-results="requirementDetails.nonExtraction"
                @goToCriterion="goToCriterion"
                @grantExceptionToCriterion="grantExceptionToCriterion"
                @grantEvaluateEntityException="grantEvaluateEntityException"
                @removeExceptions="$emit('removeExceptions', $event)"
            />
            <PolicyInfoGeneric
                v-else
                :requirement="requirement"
                :requirement-model="requirementModel"
                :evaluation-errors="evaluationErrors"
                :all-requirements="allRequirements"
                :evaluation-results="requirementDetails.evaluationResults"
                :collateral-entities="collateralEntities"
                :non-extraction-results="requirementDetails.nonExtraction"
                @goToCriterion="goToCriterion"
                @grantExceptionToCriterion="grantExceptionToCriterion"
                @grantEvaluateEntityException="grantEvaluateEntityException"
                @removeExceptions="$emit('removeExceptions', $event)"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Prop, Ref, Vue } from '@evidentid/vue-property-decorator';
    import startCase from 'lodash/startCase';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faCaretDown, faCaretRight, faEllipsisH, IconDefinition } from '@fortawesome/free-solid-svg-icons';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { Expandable } from '@evidentid/dashboard-commons/components/Expandable';
    import { Menu, MenuLink } from '@evidentid/dashboard-commons/components/Menu';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';
    import { SubmissionMethod } from '@evidentid/tprm-portal-lib/models/common/SubmissionMethod.model';
    import {
        CollateralEntity, ComplianceStatus, EntityRequirement, EntityRequirementDetails, ExceptionInput,
        ExceptionLevel, ExceptionType, TprmRequirementModel, TprmRequirementType, VerificationStatus,
    } from '@evidentid/tprm-portal-lib/models/entity-details';
    import { Entity } from '@evidentid/tprm-portal-lib/models/dashboard';
    import { EntityRiskProfile } from '@evidentid/tprm-portal-lib/models/decisioning';
    import ComplianceStatusBadge from '@/modules/dashboard/components/ComplianceStatusBadge/ComplianceStatusBadge.vue';
    import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
    import NoPolicy from '@/modules/entity-details/components/NoPolicy/NoPolicy.vue';
    import ExceptionBadge from '@/modules/entity-details/components/ExceptionBadge/ExceptionBadge.vue';
    import { getRequirementTypeIcon } from '@/modules/entity-details/utils/getRequirementTypeIcon';
    import {
        createCriterionLevelException,
        createRequirementLevelException,
    } from '@/modules/entity-details/utils/createException';
    import EvaluationRuleRequirementIcon
        from '@/modules/entity-details/components/EvaluationRuleRequirementIcon/EvaluationRuleCoverageIcon.vue';
    import DocumentDataOnlyBadge
        from '@/modules/entity-details/components/DocumentDataOnlyBadge/DocumentDataOnlyBadge.vue';
    import DocumentViewButton from '@/modules/entity-details/components/DocumentViewButton/DocumentViewButton.vue';
    import PolicyInfo from './sub-components/PolicyInfo/PolicyInfo.vue';
    import PolicyInfoGeneric from '@/modules/entity-details/components/Policy/sub-components/PolicyInfoGeneric/PolicyInfoGeneric.vue';
    import { uiRequirementTypeDetails } from '@/constants/uiRequirementTypeDetails.constant';
    import { UiRequirementTypeCategory } from '@/modules/entity-details/models/UiRequirementTypeDetails.model';
    import {
        isFieldImportApiOrWithoutDocumentUpload,
    } from '@/modules/entity-details/utils/isFieldImportApiOrWithoutDocumentUpload/isFieldImportApiOrWithoutDocumentUpload';

    @Component({
        components: {
            PolicyInfoGeneric,
            DocumentDataOnlyBadge,
            DocumentViewButton,
            EidIcon,
            EvaluationRuleRequirementIcon,
            ExceptionBadge,
            Expandable,
            Menu,
            MenuLink,
            PolicyInfo,
            FontAwesomeIcon,
            ComplianceStatusBadge,
            NoPolicy,
        },
    })
    export default class Policy extends Vue {
        @Prop({ type: Object, required: true })
        private entity!: Entity;

        @Prop({ type: Object, required: true })
        private requirementDetails!: EntityRequirementDetails;

        @Prop({ type: Array, default: () => [] })
        private allRequirements!: EntityRequirementDetails[];

        @Prop({ default: null })
        private icon!: IconDefinition | null;

        @Prop({ type: Boolean, default: true })
        private showRequirementTypeIcon!: boolean;

        @Prop({ type: Array, default: () => [] })
        private entityRiskProfiles!: EntityRiskProfile[];

        @Prop({ type: Array, default: () => [] })
        private requirementModels!: TprmRequirementModel[];

        @Prop({ type: Object as PropType<Record<string, FieldEvaluationResultError>>, default: () => ({}) })
        private evaluationErrors!: Record<string, FieldEvaluationResultError>;

        @Prop({ type: Array as PropType<CollateralEntity[]>, default: () => [] })
        private collateralEntities!: CollateralEntity[];

        @Ref()
        private actionMenu!: Expandable;

        private faCaretRight = faCaretRight;
        private faCaretDown = faCaretDown;
        private evidentGreenColor = tailwindColors.eidTrustGreen.DEFAULT;

        private opened: boolean = false;

        private faEllipsisH = faEllipsisH;
        private ComplianceStatus = ComplianceStatus;
        private insuranceRequirements = uiRequirementTypeDetails
            .filter((requirement) => requirement.category === UiRequirementTypeCategory.insurance)
            .map((requirement) => requirement.name);

        private get hasValidRequirementForDocumentButtonOrBadge(): boolean {
            return this.requirement != null
                && this.requirement.complianceStatus !== ComplianceStatus.new
                && !this.requirementProcessing;
        }

        private get isFieldImportApiOrWithoutDocumentUploadVal(): boolean {
            return isFieldImportApiOrWithoutDocumentUpload({
                submissionMethod: this.requirement?.submissionMethod,
                tprmRequirementType: this.requirementDetails.coverageType,
            });
        }

        private get isShowingDataOnlyBadge(): boolean {
            return this.hasValidRequirementForDocumentButtonOrBadge
                && this.isFieldImportApiOrWithoutDocumentUploadVal;
        }

        private get isShowingDocumentViewButton(): boolean {
            return this.hasValidRequirementForDocumentButtonOrBadge && !this.isFieldImportApiOrWithoutDocumentUploadVal;
        }

        private get caretIcon(): IconDefinition {
            return this.opened ? this.faCaretDown : this.faCaretRight;
        }

        private get requirementModel(): TprmRequirementModel | undefined {
            return this.requirementModels
                .find((model) => model.coverageType === this.requirementDetails.coverageType);
        }
        private get requirementTypeLabel(): string {
            return this.requirementModel?.label || startCase(this.requirementDetails.coverageType.toLowerCase());
        }

        private get requirement(): EntityRequirement | null {
            return this.requirementDetails.coverage || null;
        }

        private get requirementTypeIcon(): string {
            return getRequirementTypeIcon(this.requirementDetails.coverageType as TprmRequirementType);
        }

        private get hasCoverageLevelException(): boolean {
            const statusOverride = this.requirementDetails.complianceStatusMetadata?.statusOverride;
            const level = statusOverride?.level;
            return Boolean(level && level === ExceptionLevel.coverageType);
        }

        private get hasEntityLevelException(): boolean {
            const statusOverride = this.requirementDetails.complianceStatusMetadata?.statusOverride;
            const level = statusOverride?.level;
            return Boolean(level && level === ExceptionLevel.insured);
        }

        private get exceptionLabel(): string {
            return this.hasCoverageLevelException ? 'Exception Granted' : 'Exception';
        }

        private get hasAnyAction(): boolean {
            return Boolean((
                this.requirementDetails.complianceStatusMetadata?.statusOverride ||
                this.requirementDetails.complianceStatus === ComplianceStatus.nonCompliant
            ));
        }

        private get showComplianceStatus(): boolean {
            const hasNonNewStatus = Boolean(this.requirementDetails.complianceStatus) &&
                this.requirementDetails.complianceStatus !== ComplianceStatus.new;
            const hasNonCompliantStatus =
                this.requirementDetails.complianceStatus === ComplianceStatus.nonCompliant;
            const hasEva = this.requirementDetails.evaluationResults?.length > 0;
            if (!hasEva && hasNonCompliantStatus) {
                return true;
            }
            const nonCompliantResults = this.requirementDetails.evaluationResults
                .filter((result) => result.result === ComplianceStatus.nonCompliant);
            const allNonCompliantResultsAreNotUsedInCalculation = nonCompliantResults.length > 0
                && nonCompliantResults.every((result) => !result.useDuringComplianceCalculation);
            if (hasEva && allNonCompliantResultsAreNotUsedInCalculation) {
                return false;
            }
            return hasEva && hasNonNewStatus && !this.requirementProcessing;
        }

        private get isEvaluationRuleRequirement(): boolean {
            return this.entityRiskProfiles.some(
                (group) => group.evaluationRule?.coverageTypes.includes(this.requirementDetails.coverageType),
            );
        }

        private get noPolicy() {
            return this.requirementProcessing ||
                this.requirementDetails.declineReason ||
                this.requirement === null ||
                this.requirement.details?.status === false ||
                this.requirement.details?.presence === false;
        }

        private get requirementProcessing(): boolean {
            if (
                this.entity.complianceStatus === ComplianceStatus.pending
                && [ VerificationStatus.processing, VerificationStatus.actionsReview ]
                    .includes(this.entity.verificationStatus)
            ) {
                return true;
            }

            return !this.requirementDetails.complianceStatus
                && this.requirementDetails.verificationStatus === VerificationStatus.processing;
        }

        private get exceptionUntil(): string | null {
            const exception = this.requirementDetails.complianceStatusMetadata?.statusOverride;
            if (!exception) {
                return null;
            }
            if (exception.type === ExceptionType.untilCoverageExpiration) {
                return this.requirementDetails.coverage?.policy.expirationDate || null;
            } else {
                return exception.until || null;
            }
        }

        private toggle(): void {
            this.opened = !this.opened;
        }

        private showDocument(): void {
            this.$procedures.execute('showEntityRequirementRprDocuments', {
                rpName: this.$rp.current!,
                email: this.entity.contactEmail || '',
                requirementType: this.requirementDetails.coverageType,
                requirementTypeLabel: this.requirementTypeLabel,
                entityId: this.entity.id,
                verificationId: this.requirement?.requestId,
            });
        }

        private goToCriterion(riskProfileId: string, coverageType: TprmRequirementType): void {
            this.$emit('goToCriterion', riskProfileId, coverageType);
        }

        private grantExceptionForRequirement(): void {
            this.$emit(
                'grantExceptions',
                [ createRequirementLevelException(this.requirementDetails.coverageType) ],
            );
        }

        private grantEvaluateEntityException(exception: ExceptionInput[]): void {
            this.$emit('grantExceptions', exception);
        }

        private grantExceptionToCriterion(criterionId: string[]): void {
            this.$emit(
                'grantExceptions',
                criterionId.map((id) => createCriterionLevelException(this.requirementDetails.coverageType, id)),
            );
        }

        private grantExceptionForNonCompliantCriteria(): void {
            const criterionIds = Object.entries(this.evaluationErrors)
                .filter(([ key, value ]) => (
                    key.includes(this.requirementDetails.coverageType) &&
                    value.complianceStatusMetadata.length === 0
                ))
                .map(([ _, value ]) =>
                    value.criterionId,
                )
                .reduce((acc, exceptions) => [ ...acc, ...exceptions ], [] as string[]);
            const distinctIds = [ ...new Set(criterionIds) ];
            const exceptions =
                distinctIds.map((id) => createCriterionLevelException(this.requirementDetails.coverageType, id));
            this.$emit('grantExceptions', exceptions);
        }

        private get isStatusOverridePresent(): boolean {
            return Boolean(this.requirementDetails.complianceStatusMetadata?.statusOverride);
        }

        private get isInsuranceRequirements(): boolean {
            return this.insuranceRequirements.includes(this.requirementDetails.coverageType);
        }
    }
</script>
