<!-- eslint-disable max-lines -->
<!--TODO(PRODUCT-17632): refactor and make this file smaller-->
<template>
    <Page :loading="rpChangeCount > 0" title="Dashboard">
        <template v-if="!loadingCategoryStatistics && categoryStatistics.total === 0">
            <EntitiesEmptyState
                :loading="loadingCustomProperties || loadingRequirementTypes"
                @downloadSampleCsv="downloadSampleCsv"
                @openAddIndividualEntityModal="openAddIndividualEntityModal"
                @openBulkImportEntitiesModal="openBulkImportEntitiesModal"
            />
        </template>
        <template v-else-if="selectedEntity">
            <EntityDetails
                :currently-updated-entities-ids="entityLoadingIdList"
                :requirement-details-list="coverageDetailsListStatus.list"
                :collateral-entities="collateralEntitiesStatus.list"
                :entity-risk-profiles="entityRiskProfilesStatus.list"
                :entity="selectedEntity"
                :custom-properties="customPropertiesStatus.list"
                :is-loading-submission-link="loadingSubmissionLink"
                :submission-link-per-entity-id="submissionLinkPerEntityId"
                :loading="loadingEntityDetails || loadingEntityRiskProfiles"
                :is-collateral-enabled="isCollateralEnabled"
                :requirement-models="getRequirementModelsWithCountryLabel(selectedEntity)"
                :external-alert-config="entityDetailsAlertConfig"
                :collateral-custom-property-key="collateralCustomPropertyKey"
                :enum-labels="categorizedEnumLabels"
                @close="closeEntityDetails"
                @goToCriterion="goToCriterion"
                @patchEntity="patchEntities"
                @updateEntities="updateEntities"
                @deactivateEntities="deactivateEntities"
                @setEntitiesPauseState="setEntitiesPauseState"
                @sendDocumentRequest="onSendDocumentRequestFromEntityDetails"
                @copySubmissionLink="copySubmissionLink"
                @loadEntityRequests="onActionsButtonClicked"
                @evaluationUpdated="onEvaluationUpdated"
                @open-historic-document-modal="openHistoricDocumentModal"
            />
        </template>
        <template v-else>
            <DashboardAlertList :configs="alertConfigList" />
            <CategoryTiles
                v-if="entityStatisticsStatus.statistics"
                :value="categoryStatistics"
                :selected="selectedCategory"
                :disabled="entitiesStatus.loading"
                @input="onSelectCategory"
            />
            <InfiniteScroll @scroll="loadNextEntities">
                <EntitiesTable
                    :entities="entitiesStatus.list"
                    :finished="entitiesStatus.finished"
                    :loading="entitiesStatus.loading"
                    :exporting="isExporting"
                    :sort="sorting"
                    :displayed-custom-properties="customPropertiesToDisplay"
                    :currently-updated-entities-ids="entityLoadingIdList"
                    :is-performing-bulk-action="patchEntitiesStatus.isBulk"
                    :is-loading-submission-link="loadingSubmissionLink"
                    :submission-link-per-entity-id="submissionLinkPerEntityId"
                    :risk-profiles="riskProfileStatus.list"
                    @copySubmissionLink="copySubmissionLink"
                    @loadEntityRequests="onActionsButtonClicked"
                    @selectEntity="showEntityDetails"
                    @sort="changeSorting"
                    @export="exportEntities"
                    @openAddIndividualEntityModal="openAddIndividualEntityModal"
                    @openBulkImportEntitiesModal="openBulkImportEntitiesModal"
                    @showDocuments="showDocuments"
                    @deactivateEntities="deactivateEntities"
                    @setEntitiesPauseState="setEntitiesPauseState"
                    @clearFilters="clearFilters"
                    @sendDocumentRequest="onSendDocumentRequestFromEntitiesTable"
                    @grantExceptionToEntity="grantException"
                    @removeExceptions="removeExceptions"
                    @open-historic-document-modal="openHistoricDocumentModal"
                >
                    <template #toolbar>
                        <EntitySearchAndFilter
                            :filters="filters"
                            :risk-profiles="riskProfileStatus.list"
                            :effective-risk-profiles="effectiveRiskProfilesStatus.list"
                            :custom-properties="customPropertiesOther"
                            :disabled="entitiesStatus.loading"
                            :collateral-custom-property-key="collateralCustomPropertyKey"
                            :custom-property-collaterals="customPropertyCollaterals"
                            @change="changeFilters"
                        />
                        <FiltersChipList
                            v-if="hasAnyDisplayableFilters"
                            :filters="filters"
                            :custom-properties="customPropertiesOther"
                            :disabled="entitiesStatus.loading"
                            :collateral-custom-property-key="collateralCustomPropertyKey"
                            @closeFilters="closeFilters"
                        />
                    </template>
                </EntitiesTable>
            </InfiniteScroll>
        </template>
    </Page>
</template>

<script lang="ts">
    /* eslint-disable max-lines */
    import { isEmpty, pick, pickBy, omit, sortBy, uniq } from 'lodash';
    import { Component, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { EntityRiskProfile } from '@evidentid/tprm-portal-lib/models/decisioning';
    import { TprmRequirementType, VerificationStatus } from '@evidentid/tprm-portal-lib/models/entity-details';
    import { InfiniteScroll } from '@evidentid/dashboard-commons/components/InfiniteScroll';
    import {
        deserializeSorting,
        serializeMultiSorting,
        serializeSorting,
        Sorting,
        SortingDirection,
    } from '@evidentid/universal-framework/sorting';
    import { Alert } from '@evidentid/dashboard-commons/components/Alert';
    import { AlertConfig, AlertType } from '@evidentid/dashboard-commons/components/Alert/types';
    import Page from '@/layouts/Page.vue';
    import { downloadSampleCsv } from '@/modules/entity-management/utils/csv-sample';
    import {
        BatchAddEntitiesStatus,
        BatchUpdateEntitiesStatus,
        PatchEntitiesStatus,
    } from '@/modules/entity-management/vuex';
    import CategoryTiles from '@/modules/entity-filtering/components/CategoryTiles/CategoryTiles.vue';
    import EntitySearchAndFilter
        from '@/modules/entity-filtering/components/EntitySearchAndFilter/EntitySearchAndFilter.vue';
    import { EntityFilterCategory, EntityFilters } from '@/modules/entity-filtering/types';
    import {
        categoryFilterMap,
        getCategoryByFilters,
        getEntityCollateralFieldFiltersKeys,
        getCustomPropertyFilterKeys,
        getEntityStandardFilterKeys,
        parseFiltersForApi,
    } from '@/modules/entity-filtering/utils/entityFilterUtils';
    import EntitiesTable from '@/modules/dashboard/components/EntitiesTable/EntitiesTable.vue';
    import EntitiesEmptyState from '@/modules/dashboard/components/EntitiesEmptyState/EntitiesEmptyState.vue';
    import { EntityPatch, PatchOperationType } from '@evidentid/rpweb-api-client';
    import FiltersChipList from '@/modules/entity-filtering/components/ActiveFiltersChipList/FiltersChipList.vue';
    import EntityDetails from '@/modules/entity-details/components/EntityDetails/EntityDetails.vue';
    import { LogoLoader } from '@evidentid/dashboard-commons/components/LogoLoader';
    import { SendRequestStatus } from '@/modules/entity-request/vuex';
    import { createEmptyRequestsConfigStatus, DashboardState } from '@/modules/dashboard/vuex/dashboard';
    import { createEntityLevelException } from '@/modules/entity-details/utils/createException';
    import {
        RiskProfilesStatus,
        RequirementModelsStatus,
        EntityEffectiveRiskProfileStatus,
    } from '@/modules/decisioning-criteria/vuex';
    import DashboardAlertList from '@/modules/dashboard/components/DashboardAlertList/DashboardAlertList.vue';
    import { ActionableAlertConfig } from '@/modules/entity-details/components/ActionableAlert/types';
    import { CollateralEntity } from '@evidentid/tprm-portal-lib/models/entity-details/Collateral/CollateralEntity.model';
    import { isCollateralCustomProperty } from '@/utils/isCollateralCustomProperty';
    import { shouldEnableDocumentRequest } from '@/utils/should-enable-document-request/shouldEnableDocumentRequest';
    import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
    import { getRequirementTypeLabelByCountry } from '@/utils/get-requirement-type-label-by-country/getRequirementTypeLabelByCountry';
    import { CustomProperty, Entity, EntityInput } from '@evidentid/tprm-portal-lib/models/dashboard';
    import { EntityStatistics } from '@evidentid/tprm-portal-lib/models/dashboard/EntityStatistics.model';
    import {
        DashboardConfiguration,
    } from '@evidentid/tprm-portal-lib/models/dashboard-configuration/DashboardConfiguration.model';
    import {
        EntityRequirementDetails,
    } from '@evidentid/tprm-portal-lib/models/entity-details/EntityRequirement.model';
    import { TprmRequestsConfig } from '@evidentid/tprm-portal-lib/models/common/TprmRequestsConfig.model';
    import {
        VerificationRequest,
    } from '@evidentid/tprm-portal-lib/models/notification-configuration/VerificationRequest.model';
    import {
        TprmRequirementModel,
    } from '@evidentid/tprm-portal-lib/models/entity-details/TprmRequirementModel.model';

    type AlertConfigName =
        'alertConfig'
        | 'additionalAlertConfig'
        | 'entityDetailsAlertConfig'
        | 'collectEvidenceAlert'
        | 'errorAlertConfig';
    type AlertTimeoutName =
        'alertConfigTimeout'
        | 'additionalAlertConfigTimeout'
        | 'entityDetailsAlertTimeout'
        | 'collectEvidenceAlertTimeout'
        | 'errorAlertTimeout';
    type FinishDocumentRequestCallbackFnc = (...args: unknown[]) => Promise<unknown>;

    @Component({
        components: {
            DashboardAlertList, Page, InfiniteScroll, EntitiesTable, Alert, FontAwesomeIcon, EntitiesEmptyState,
            CategoryTiles, EntitySearchAndFilter, FiltersChipList, EntityDetails, LogoLoader,
        },
    })
    export default class InsuranceDashboard extends Vue {
        private readonly defaultSorting = { column: 'displayName', direction: SortingDirection.asc };
        private lastRpName!: string;
        private alertConfig: AlertConfig | null = null;
        private alertConfigTimeout: any = null;
        private additionalAlertConfig: AlertConfig | null = null;
        private additionalAlertConfigTimeout: any = null;
        private entityDetailsAlertConfig: ActionableAlertConfig | null = null;
        private entityDetailsAlertTimeout: any = null;
        private collectEvidenceAlert: AlertConfig | null = null;
        private collectEvidenceAlertTimeout: any = null;
        private errorAlertConfig: AlertConfig | null = null;
        private errorAlertTimeout: any = null;
        private customPropertiesToDisplay: CustomProperty[] = [];
        private rpChangeCount: number = 0;
        private loadingSubmissionLink: boolean = false;

        @Watch('$rp.current', { immediate: true })
        private async handleRpChange(rpName: string, prevRpName: string) {
            // Save information about the last resource, which should be cleared during destroy
            this.lastRpName = rpName;
            ++this.rpChangeCount;

            if (prevRpName) {
                this.$store.actions.dashboard.clearCustomPropertiesList({ rpName: prevRpName });
                this.$store.actions.dashboard.clearRequirementTypeList({ rpName: prevRpName });
                this.$store.actions.dashboard.clearEntitiesList({ rpName: prevRpName });
                this.$store.actions.dashboard.clearEntityStatistics({ rpName: prevRpName });
                this.$store.actions.dashboard.clearEntityRequests({ rpName: prevRpName });
                this.$store.actions.dashboard.clearSubmissionLinks({ rpName: prevRpName });
                this.$store.actions.dashboardConfiguration.clearDashboardConfiguration({ rpName: prevRpName });
                this.$store.actions.decisioningCriteria.clearRpEntityRiskProfiles({ rpName: prevRpName });
                this.$store.actions.decisioningCriteria.clearRiskProfiles({ rpName: prevRpName });
                this.$store.actions.decisioningCriteria.clearEffectiveRiskProfilesStatus({ rpName: prevRpName });
                this.$store.actions.decisioningCriteria.clearRequirementModelsStatus({ rpName: prevRpName });
                this.closeEntityDetails();
            }
            // reloadEntity checks for filter which is relying on customProperties to be loaded first
            await this.loadCustomProperties();
            await Promise.all([
                this.loadRequirementTypes(),
                this.reloadEntities(),
                this.loadEntityStatistics(),
                this.loadDashboardConfiguration(),
                this.loadRiskProfiles(),
                this.loadEffectiveRiskProfiles(),
                this.loadRequirementModels(),
            ]);
            const entityId = this.$route.query?.entity || null;
            if (entityId && typeof entityId === 'string') {
                await this.$store.actions.dashboard.loadEntity({ rpName: this.rpName, id: entityId });
                await this.loadEntityCoverageData({ rpName: this.rpName, entityId });
            }
            --this.rpChangeCount;
        }

        @Watch('$route.query')
        private async handleQueryChange() {
            // Reload results
            const entityId = this.$route.query?.entity || null;
            if (!entityId) {
                this.reloadEntities();
            } else if (typeof entityId === 'string') {
                await this.loadEntityCoverageData({ rpName: this.rpName, entityId });
            }
        }

        private async loadEntityCoverageData(
            payload: { rpName: string, entityId: string },
        ): Promise<void[]> {
            const { rpName, entityId } = payload;
            return Promise.all([
                this.$store.actions.entityDetails.loadRequirementDetailsList({ rpName, entityId }),
                this.$store.actions.entityDetails.loadCollateralEntities({ rpName, entityId }),
                this.loadEntityRiskProfiles(),
            ]);
        }

        private async loadNextEntities() {
            if (!this.entitiesStatus.loading && !this.entitiesStatus.finished) {
                const apiFilters = pickBy(this.filters, Boolean);
                const modifiedSort = (this.sorting && this.sorting.column === 'complianceStatus')
                    ? serializeMultiSorting([ { column: 'active', direction: SortingDirection.desc }, this.sorting ])
                    : serializeSorting(this.sorting);
                await this.$store.actions.dashboard.loadNextEntities({
                    rpName: this.rpName,
                    sort: modifiedSort || null,
                    filters: parseFiltersForApi(
                        apiFilters,
                        this.customPropertiesOther,
                        this.collateralCustomPropertyKey,
                        this.customPropertyCollaterals,
                    ),
                });
            }
        }

        private async loadEntityStatistics() {
            if (this.entityStatisticsStatus.status !== OperationStatus.loading) {
                await this.$store.actions.dashboard.loadEntityStatistics({ rpName: this.rpName });
            }
        }

        private async loadCustomProperties() {
            if (!this.loadingCustomProperties) {
                await this.$store.actions.dashboard.loadCustomProperties({ rpName: this.rpName });
            }
        }

        private async loadRequirementTypes() {
            if (!this.loadingRequirementTypes) {
                await this.$store.actions.dashboard.loadRequirementTypes({ rpName: this.rpName });
            }
        }

        private async loadDashboardConfiguration() {
            if (!this.loadingDashboardConfiguration) {
                await this.$store.actions.dashboardConfiguration.loadDashboardConfiguration({ rpName: this.rpName });
            }
        }

        private async loadRiskProfiles() {
            if (this.riskProfileStatus.status !== OperationStatus.loading) {
                await this.$store.actions.decisioningCriteria.loadRiskProfiles({ rpName: this.rpName });
            }
        }

        private async loadEffectiveRiskProfiles() {
            if (this.effectiveRiskProfilesStatus.status !== OperationStatus.loading) {
                await this.$store.actions.decisioningCriteria.loadEffectiveRiskProfiles({ rpName: this.rpName });
            }
        }

        private async loadRequirementModels() {
            if (this.requirementModelsStatus.status !== OperationStatus.loading) {
                this.$store.actions.decisioningCriteria.loadRequirementModels({ rpName: this.rpName });
            }
        }

        private async loadEntityRiskProfiles() {
            if (!this.loadingEntityRiskProfiles && this.selectedEntity) {
                await this.$store.actions.decisioningCriteria.loadEntityRiskProfiles({
                    rpName: this.rpName,
                    entityId: this.selectedEntity.id,
                });
            }
        }

        @Watch('customPropertiesStatus')
        private handleCustomPropertiesStatusChange() {
            this.loadCustomPropertiesForDisplay();
        }

        @Watch('dashboardConfiguration')
        private handleDashboardConfigurationChange() {
            this.loadCustomPropertiesForDisplay();
        }

        private loadCustomPropertiesForDisplay() {
            if (this.customPropertiesStatus.status === OperationStatus.success &&
                this.dashboardConfiguration.status === OperationStatus.success) {
                this.customPropertiesToDisplay = this.dashboardConfiguration.config.insuredFields
                    .map((fieldId: string) => this.customPropertiesStatus.list.find((field) => field.id === fieldId))
                    .filter(Boolean) as CustomProperty[];
            }
        }

        private get loadingCategoryStatistics(): boolean {
            return this.entityStatisticsStatus.status === OperationStatus.loading;
        }

        private get loadingCustomProperties(): boolean {
            return this.customPropertiesStatus.status === OperationStatus.loading;
        }

        private get loadingRequirementTypes(): boolean {
            return this.requirementTypesStatus.status === OperationStatus.loading;
        }

        private get loadingDashboardConfiguration(): boolean {
            return this.dashboardConfiguration.status === OperationStatus.loading;
        }

        private get loadingEntityDetails(): boolean {
            return this.coverageDetailsListStatus.status === OperationStatus.loading;
        }

        private get loadingCollateralEntities(): boolean {
            return this.collateralEntitiesStatus.status === OperationStatus.loading;
        }

        private get loadingEntityRiskProfiles(): boolean {
            return this.entityRiskProfilesStatus.status === OperationStatus.loading;
        }

        private get rpName(): string {
            return this.$rp?.current || '';
        }

        private get entitiesStatus() {
            return (this.$store.state.dashboard as DashboardState).entities[this.rpName] || {
                count: Infinity,
                finished: false,
                loading: false,
                list: [],
            };
        }

        private get customPropertiesStatus(): { status: OperationStatus, list: CustomProperty[] } {
            return this.$store.state.dashboard.customProperties[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get customPropertiesOther(): CustomProperty[] {
            return this.customPropertiesStatus.list.filter((customProperty) =>
                !isCollateralCustomProperty(customProperty),
            );
        }

        private get customPropertyCollaterals(): CustomProperty | null {
            return this.customPropertiesStatus.list.find(isCollateralCustomProperty) || null;
        }

        private get collateralCustomPropertyKey(): string {
            return this.customPropertyCollaterals?.key || '';
        }

        private get requirementTypesStatus(): { status: OperationStatus, list: string[] } {
            return this.$store.state.dashboard.requirementTypes[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get dashboardConfiguration(): {
            status: OperationStatus;
            config: DashboardConfiguration;
        } {
            return this.$store.state.dashboardConfiguration.configuration[this.rpName] || {
                status: OperationStatus.uninitialized,
                config: { insuredFields: [] },
            };
        }

        private get entityStatisticsStatus(): { status: OperationStatus, statistics: EntityStatistics } {
            return this.$store.state.dashboard.entityStatistics[this.rpName] || {
                status: OperationStatus.uninitialized,
                statistics: {
                    compliance: {
                        forcePlaced: 0,
                        compliant: 0,
                        nonCompliant: 0,
                        pending: 0,
                        new: 0,
                    },
                    expiration: {
                        within30Days: 0,
                    },
                },
            };
        }

        private get categoryStatistics(): Record<EntityFilterCategory | 'total', number> {
            const { compliance, expiration } = this.entityStatisticsStatus.statistics;
            return {
                [EntityFilterCategory.compliantAndNotExpiringSoon]: compliance.compliant - expiration.within30Days,
                [EntityFilterCategory.nonCompliant]: compliance.nonCompliant,
                [EntityFilterCategory.pendingAndNew]: compliance.pending + compliance.new,
                [EntityFilterCategory.compliantAndExpiringSoon]: expiration.within30Days,
                total: Object.entries(compliance).reduce((acc, [ , value ]) => acc + value, 0),
            };
        }

        private get exportStatus(): OperationStatus {
            return this.$store.getStatus('exportEntities', 'exportStatus', this.rpName);
        }

        private get isExporting(): boolean {
            return this.exportStatus === OperationStatus.loading;
        }

        private get sorting(): Sorting {
            return deserializeSorting(this.$route.query.sort) || this.defaultSorting;
        }

        private get filters(): EntityFilters {
            const standardFilters = pick(this.$route.query, getEntityStandardFilterKeys());
            let customPropertyFilters = {};
            let collateralFieldFilters = {};

            if (this.customPropertiesStatus.status === OperationStatus.success) {
                customPropertyFilters = pick(
                    this.$route.query,
                    getCustomPropertyFilterKeys(this.customPropertiesOther),
                ) as Record<string, string>;
                collateralFieldFilters = pick(
                    this.$route.query,
                    getEntityCollateralFieldFiltersKeys(this.collateralCustomPropertyKey),
                ) as Record<string, string>;
            }

            const filters = { ...standardFilters };

            if (!isEmpty(customPropertyFilters)) {
                Object.assign(filters, { customPropertyFilters });
            }

            if (!isEmpty(collateralFieldFilters)) {
                Object.assign(filters, { collateralFieldFilters });
            }

            return filters;
        }

        private get hasAnyDisplayableFilters(): boolean {
            const { search, ...filters } = this.filters;
            return Object.keys(filters).length > 0;
        }

        private get selectedCategory(): EntityFilterCategory[] {
            const category = getCategoryByFilters(this.filters);
            return category ? [ category ] : [];
        }

        private get addEntitiesStatus(): BatchAddEntitiesStatus {
            return this.$store.state.entityManagement.addEntitiesStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                count: 0,
                successCount: 0,
                successes: [],
                failures: [],
            };
        }

        private get updateEntitiesStatus(): BatchUpdateEntitiesStatus {
            return this.$store.state.entityManagement.updateEntitiesStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                successCount: 0,
                failureCount: 0,
                totalCount: 0,
                successes: [],
                failures: [],
            };
        }

        private get patchEntitiesStatus(): PatchEntitiesStatus {
            return this.$store.state.entityManagement.patchEntitiesStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                successCount: 0,
                failureCount: 0,
                totalCount: 0,
                successes: [],
                failures: [],
                currentlyUpdated: [],
                isBulk: false,
            };
        }

        private get sendRequestStatus(): SendRequestStatus {
            return this.$store.state.entityRequest.sendDocumentRequestStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                entityIds: [],
                count: 0,
                successCount: 0,
                failureCount: 0,
                successes: [],
                failures: [],
            };
        }

        private get coverageDetailsListStatus(): { status: OperationStatus, list: EntityRequirementDetails[] } {
            return this.$store.state.entityDetails.requirementDetailsListStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get collateralEntitiesStatus(): { status: OperationStatus, list: CollateralEntity[] } {
            return this.$store.state.entityDetails.collateralEntitiesStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get effectiveRiskProfilesStatus(): EntityEffectiveRiskProfileStatus {
            return this.$store.state.decisioningCriteria.effectiveRiskProfileStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get requirementModelsStatus(): RequirementModelsStatus {
            return this.$store.state.decisioningCriteria.requirementModelsStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get entityRiskProfilesStatus(): {
            status: OperationStatus;
            list: EntityRiskProfile[];
        } {
            const riskProfilesPerRp = this.$store.state.decisioningCriteria.entityRiskProfiles[this.rpName];
            const riskProfiles = riskProfilesPerRp && this.selectedEntity
                ? riskProfilesPerRp[this.selectedEntity.id]
                : null;
            return riskProfiles || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get grantExceptionStatus(): OperationStatus {
            return this.$store.state.entityDetails.grantExceptionStatus[this.rpName] || OperationStatus.uninitialized;
        }

        private get removeExceptionStatus(): OperationStatus {
            return this.$store.state.entityDetails.removeExceptionStatus[this.rpName] || OperationStatus.uninitialized;
        }

        private get entityRequestsStatus():
            Record<string, { status: OperationStatus, requests: VerificationRequest[] }> {
            return this.$store.state.dashboard.requests[this.rpName]?.requests || {};
        }

        private get entitySubmissionLinksStatus():
            Record<string, { status: OperationStatus, submissionLink: string }> {
            return this.$store.state.dashboard.submissionLinks[this.rpName]?.submissionLinks || {};
        }

        private get requestsConfigStatus(): {
            status: OperationStatus;
            data: TprmRequestsConfig | null;
        } {
            return this.$store.state.dashboard.requestsConfig[this.rpName] || createEmptyRequestsConfigStatus();
        }

        private get submissionLinkPerEntityId(): Record<string, string> {
            const test = Object.entries(this.entitySubmissionLinksStatus).reduce((acc, [ requestId, linkStatus ]) => {
                const entityId = Object.keys(this.entityRequestsStatus).find((entityId) => (
                    this.entityRequestsStatus[entityId]?.requests.some((x) => x.requestId === requestId)
                ));
                return entityId ? { ...acc, [entityId]: linkStatus.submissionLink } : acc;
            }, {});
            return Object.entries(this.entitySubmissionLinksStatus).reduce((acc, [ requestId, linkStatus ]) => {
                const entityId = Object.keys(this.entityRequestsStatus).find((entityId) => (
                    this.entityRequestsStatus[entityId]?.requests.some((x) => x.requestId === requestId)
                ));
                return entityId ? { ...acc, [entityId]: linkStatus.submissionLink } : acc;
            }, {});
        }

        private get riskProfileStatus(): RiskProfilesStatus {
            return this.$store.state.decisioningCriteria.riskProfiles[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get isCollateralEnabled(): boolean {
            return this.requestsConfigStatus.data?.collateralsEvaluationEnabled || false;
        }

        private get alertConfigList(): AlertConfig[] {
            return [ this.alertConfig, this.additionalAlertConfig, this.collectEvidenceAlert, this.errorAlertConfig ]
                .filter(Boolean) as AlertConfig[];
        }

        private get categorizedEnumLabels(): CategorizedEnumLabels {
            return this.$store.state.dashboard.categorizedEnumLabels;
        }

        private changeSorting(sort: Sorting): void {
            this.$router.push({
                ...this.$route,
                query: { ...this.$route.query, sort: serializeSorting(sort) },
            } as any);
        }

        private async exportEntities(): Promise<void> {
            await this.$store.actions.exportEntities.exportEntities({ rpName: this.rpName });
            if (this.exportStatus === OperationStatus.error) {
                await this.$store.actions.snackbar.displaySnackbar({
                    message: 'Sorry, something went wrong during export. Please try again later.',
                    permanent: true,
                    success: false,
                });
            }
        }

        private async downloadSampleCsv(): Promise<void> {
            downloadSampleCsv(this.customPropertiesStatus.list, this.requirementTypesStatus.list);
        }

        private showDocuments(entity: Entity): void {
            this.$procedures.execute('showEntityRprDocuments', {
                rpName: this.rpName,
                email: entity.contactEmail,
                requirementModels: this.getRequirementModelsWithCountryLabel(entity),
            });
        }

        private get isEntitySelected(): boolean {
            return this.$route.query?.entity !== undefined;
        }

        private get selectedEntity(): Entity | null {
            const entityId = this.$route.query?.entity || null;
            return this.entitiesStatus.list.find((entity) => entity.id === entityId) || null;
        }

        private get entityLoadingIdList(): string[] {
            return uniq([ ...this.patchEntitiesStatus.currentlyUpdated, ...this.sendRequestStatus.entityIds ]);
        }

        private showEntityDetails(entity: Entity): void {
            this.hideAlert();
            this.$router.push({
                ...this.$route,
                query: { ...this.$route.query, entity: entity.id },
            } as any);
        }

        private closeEntityDetails(): void {
            if (this.isEntitySelected) {
                this.$router.push({
                    ...this.$route,
                    query: {
                        ...omit(this.$route.query, 'entity'),
                    },
                } as any);
            }
        }

        private openAddIndividualEntityModal(): void {
            this.$procedures.execute('openAddIndividualEntityModal', {
                rpName: this.rpName,
            }, this.finishAddEntityProcedure);
        }

        private openBulkImportEntitiesModal(): void {
            this.$procedures.execute('openBulkImportEntitiesModal', {
                rpName: this.rpName,
            }, this.finishBulkImportEntitiesProcedure);
        }

        private onSendDocumentRequestFromEntityDetails(entities: Entity[]): void {
            // the entities here will only contain up to 1 entity from entity details
            if (entities.every(this.isEntityNoResponse)) {
                this.sendDocumentRequestDirectly(entities, this.onEvaluationUpdated);
            } else {
                this.openSendDocumentRequestModal(entities, this.onEvaluationUpdated);
            }
        }

        private onSendDocumentRequestFromEntitiesTable(entities: Entity[]): void {
            // filter out non-qualified entity to only send those when request directly
            // keep original list for the modal in order to display how many entity (qualified or not) selected.
            const documentRequestEnabledEntities = entities.filter(shouldEnableDocumentRequest);
            documentRequestEnabledEntities.every(this.isEntityNoResponse)
                ? this.sendDocumentRequestDirectly(documentRequestEnabledEntities, this.reloadEntities)
                : this.openSendDocumentRequestModal(entities, this.reloadEntities);
        }

        private async sendDocumentRequestDirectly(
            entities: Entity[],
            finishCallbackFnc?: FinishDocumentRequestCallbackFnc,
        ): Promise<void> {
            await this.sendDocumentRequest(entities);
            await this.finishSendDocumentRequestProcedure(finishCallbackFnc)(true, entities);
        }

        private openSendDocumentRequestModal(
            entities: Entity[],
            finishCallbackFnc?: FinishDocumentRequestCallbackFnc,
        ): void {
            this.$procedures.execute('openSendDocumentRequestProcedure', {
                rpName: this.rpName,
                entities,
            }, this.finishSendDocumentRequestProcedure(finishCallbackFnc));
        }

        private async sendDocumentRequest(entities: Entity[]): Promise<void> {
            await this.$store.actions.entityRequest.sendDocumentRequest({
                rpName: this.rpName,
                entityIds: entities.map((entity) => entity.id),
            });
        }

        private async reloadEntities(): Promise<void> {
            this.$store.actions.dashboard.clearEntitiesList({ rpName: this.rpName });
            await this.loadNextEntities();
        }

        private async onEvaluationUpdated(): Promise<void> {
            const entityId = this.$route.query?.entity || null;
            if (entityId && typeof entityId === 'string') {
                await this.$store.actions.dashboard.loadEntity({ rpName: this.rpName, id: entityId });
                await this.loadEntityCoverageData({ rpName: this.rpName, entityId });
            }
        }

        private async updateEntities(entities: EntityInput[]): Promise<void> {
            await this.$store.actions.entityManagement.updateEntities(
                { rpName: this.rpName, entities, updateEntitiesListState: true });
        }

        private async patchEntities(properties: EntityPatch[]): Promise<void> {
            await this.$store.actions.entityManagement.patchEntities({
                rpName: this.rpName,
                isBulk: true,
                patch: properties,
            });
        }

        private finishAddEntityProcedure = (modified: boolean, collectEvidence?: boolean): void => {
            if (modified) {
                this.reloadEntities();
                this.loadEntityStatistics();
                this.setAddEntityAlert();
                this.clearEntitiesStatus();
                if (collectEvidence) {
                    this.displayCollectEvidenceMessage();
                }
            }
        };
        private finishSendDocumentRequestProcedure = (callbackFnc?: FinishDocumentRequestCallbackFnc) => async (
            modified: boolean,
            entities: Entity[],
        ): Promise<void> => {
            if (modified) {
                if (this.sendRequestStatus.status === OperationStatus.success) {
                    if (this.sendRequestStatus.failureCount === 0) {
                        this.showAlert(
                            'success',
                            entities.length === 1
                                ? `Request to ${entities[0].displayName} was sent.`
                                : `Requests to ${entities.length} entities were sent.`,
                        );
                    } else if (this.sendRequestStatus.failureCount > 0) {
                        this.showAlert('warning', `${this.sendRequestStatus.successCount} requests was sent,
                            ${this.sendRequestStatus.failureCount} requests have failed.`);
                    }
                } else {
                    this.showAlert('danger', 'Your request has failed. Please try again.');
                }
            }
            this.$store.actions.entityRequest.clearSendDocumentRequestStatus({ rpName: this.rpName });

            if (callbackFnc) {
                await callbackFnc();
            }
        };

        private finishBulkImportEntitiesProcedure = (
            modified: boolean,
            data: {
                sampleEntities: EntityInput[];
                collectEvidence?: boolean;
            }): void => {
            const procedureData = data || {};
            if (modified) {
                this.reloadEntities();
                this.loadEntityStatistics();
                this.setBulkImportEntitiesAlert(procedureData.sampleEntities);
                this.clearEntitiesStatus();
                if (procedureData.collectEvidence) {
                    this.displayCollectEvidenceMessage();
                }
            }
        };

        private finishDeactivateEntityProcedure = (modified: boolean, entities: Entity[]): void => {
            if (modified) {
                this.reloadEntities();
                this.loadEntityStatistics();
                this.createEntitiesActionAlert(this.patchEntitiesStatus, entities, 'deactivated');
                this.clearEntitiesStatus();
            }
        };

        private setAddEntityAlert(): void {
            if (this.addEntitiesStatus.status === OperationStatus.success) {
                if (this.addEntitiesStatus.successCount === 1) {
                    this.showAlert('success', 'Connected to new Entity');
                } else if (this.updateEntitiesStatus.status === OperationStatus.success
                    && this.updateEntitiesStatus.successCount === 1) {
                    this.showAlert('success', 'Entity updated');
                }
            }
        }

        private displayCollectEvidenceMessage(): void {
            this.setupAlertConfig(
                'highWarning',
                'We’ll collect evidence of insurance from those whose policies had already expired',
                'collectEvidenceAlert',
                'collectEvidenceAlertTimeout',
            );
        }

        private setBulkImportEntitiesAlert(sampleEntities: EntityInput[]): void {
            if (this.addEntitiesStatus.status === OperationStatus.success) {
                this.configAddSampleAlert(sampleEntities);
                this.configAddEntityAlertSuccess();
                if (this.addEntitiesStatus.failedOnRequestCount > 0
                    || this.updateEntitiesStatus.failedOnRequestCount > 0) {
                    this.configAddEntityErrorAlert();
                }
            }
        }

        private configAddSampleAlert(sampleEntities: EntityInput[]): void {
            if (sampleEntities && sampleEntities.length) {
                const message = this.addEntitiesStatus.totalCount === 0
                    ? 'Attention: All rows of Entity contain sample data. They will be ignored and are not imported'
                    : 'Attention: One or more rows of Entity contain sample data. These rows will be ignored and are not imported';
                this.showAdditionalAlert('highWarning', message);
            }
        }

        private configAddEntityAlertSuccess(): void {
            const connectionWord = this.addEntitiesStatus.successCount > 1 ? 'connections' : 'connection';
            const insertMessage = this.addEntitiesStatus.successCount > 0
                ? `${this.addEntitiesStatus.successCount} new ${connectionWord} added`
                : '';
            const entityWord = this.updateEntitiesStatus.successCount > 1 ? 'entities' : 'entity';
            const updateMessage = this.updateEntitiesStatus.successCount > 0
                ? `${this.updateEntitiesStatus.successCount} ${entityWord} updated`
                : '';
            const comma = insertMessage && updateMessage ? ', ' : '';
            const finalMessage = `${insertMessage}${comma}${updateMessage}`;
            if (finalMessage) {
                this.showAlert('success', finalMessage);
            }
        }

        private configAddEntityErrorAlert(): void {
            const insertCount = this.addEntitiesStatus.failedOnRequestCount;
            const updateCount = this.updateEntitiesStatus.failedOnRequestCount;
            const connectionWord = insertCount ? 'connections' : 'connection';
            const entityWord = updateCount > 0 ? 'entities' : 'entity';
            const insertMessage = insertCount > 0 ? `${insertCount} ${connectionWord} failed to be connected.` : '';
            const updateMessage = updateCount > 0 ? `${updateCount} ${entityWord} failed to be updated.` : '';
            const failureMessage = `Due to unknown errors, ${insertMessage} ${updateMessage}`;
            this.errorAlertConfig = {
                type: 'danger',
                title: failureMessage,
            };
            this.errorAlertTimeout = setTimeout(() => {
                this.errorAlertConfig = null;
            }, 5000);
        }

        private clearEntitiesStatus(): void {
            this.$store.actions.entityManagement.clearAddEntitiesStatus({ rpName: this.rpName });
            this.$store.actions.entityManagement.clearUpdateEntitiesStatus({ rpName: this.rpName });
            this.$store.actions.entityManagement.clearPatchEntitiesStatus({ rpName: this.rpName });
        }

        private changeFilters(filters: EntityFilters): void {
            const { customPropertyFilters, collateralFieldFilters, ...standardFilters } = filters;
            const sort = this.$route.query?.sort || undefined;
            this.$router.push({
                ...this.$route,
                query: pickBy({
                    sort,
                    ...standardFilters,
                    ...customPropertyFilters,
                    ...collateralFieldFilters,
                }, Boolean),
            } as any);
        }

        private closeFilters(filters: string[]) {
            this.$router.push({
                ...this.$route,
                query: {
                    ...omit(this.$route.query, filters),
                },
            } as any);
        }

        private onSelectCategory({ changed, value }: { changed: boolean, value: EntityFilterCategory[] }) {
            const searchFilterOnly = Object.keys(this.filters).length === 1 && this.filters.hasOwnProperty('search');
            const noFilters = Object.keys(this.filters).length === 0;
            if (noFilters || searchFilterOnly || getCategoryByFilters(this.filters)) {
                this.changeCategory({ changed, value });
            } else {
                this.$procedures.execute('confirmFilterOverride', {}, this.onConfirmFilterFinish({ changed, value }));
            }
        }

        private onConfirmFilterFinish({ changed, value }: { changed: boolean, value: EntityFilterCategory[] }): any {
            return (modified: boolean) => {
                if (modified) {
                    this.changeCategory({ changed, value });
                }
            };
        }

        private changeCategory({ changed, value }: { changed: boolean, value: EntityFilterCategory[] }): void {
            const sort = this.$route.query?.sort || undefined;
            const search = this.$route.query?.search || undefined;
            const filtersObj = changed ? categoryFilterMap[value[0]] : {};
            this.$router.push({
                ...this.$route,
                query: pickBy({ sort, search, ...filtersObj }, Boolean),
            } as any);
        }

        private async setEntitiesPauseState(
            { entities, isBulk, value }: { entities: Entity[], isBulk: boolean, value: boolean },
        ): Promise<void> {
            await this.$store.actions.entityManagement.patchEntities({
                rpName: this.rpName,
                isBulk,
                patch: entities.map((entity) => ({
                    id: entity.id,
                    paused: {
                        op: PatchOperationType.replace,
                        newValue: value,
                    },
                }) as EntityPatch),
            });
            this.createEntitiesActionAlert(this.patchEntitiesStatus, entities, value ? 'paused' : 'unpaused');
        }

        private deactivateEntities({ entities, isBulk }: { entities: Entity[], isBulk: boolean }): void {
            this.$procedures.execute('openDeactivateEntityProcedure', {
                rpName: this.rpName,
                entities,
                isBulk,
            }, this.finishDeactivateEntityProcedure);
        }

        private clearFilters(): void {
            this.changeFilters({});
        }

        private createEntitiesActionAlert(
            status: PatchEntitiesStatus,
            entities: Entity[],
            operation: string,
        ): void {
            if (status.totalCount > 1) {
                this.createBulkEntitiesActionAlert(status, operation);
            } else {
                this.createSingleEntityActionAlert(status, entities[0], operation);
            }
        }

        private createSingleEntityActionAlert(
            status: PatchEntitiesStatus,
            entity: Entity,
            operation: string,
        ): void {
            const showAlertFunc = this.selectedEntity && entity.id === this.selectedEntity.id
                ? this.showAlertInEntityDetails
                : this.showAlert;
            if (status.status === OperationStatus.success) {
                // TODO: revisit to see if successCount has to be 1 here
                if (status.successCount === 1) {
                    showAlertFunc('success', `${entity.contactEmail} was ${operation}`);
                } else {
                    showAlertFunc('danger', `Something went wrong! ${entity.contactEmail} was not ${operation}`);
                }
            } else if (status.status === OperationStatus.error) {
                showAlertFunc(
                    'danger',
                    `Something went wrong! ${entity.contactEmail} was not ${operation}`,
                );
            }
        }

        private createBulkEntitiesActionAlert(
            status: PatchEntitiesStatus,
            operation: string,
        ): void {
            if (status.status === OperationStatus.success) {
                if (status.successCount === 0) {
                    this.showAlert(
                        'danger',
                        `Something went wrong! ${status.failureCount} entities were not ${operation}`,
                    );
                } else {
                    if (status.successCount === 1) {
                        this.showAlert('success', `${status.successCount} entity was ${operation}`);
                    } else {
                        this.showAlert('success', `${status.successCount} entities were ${operation}`);
                    }
                    if (status.failureCount > 0) {
                        if (status.failureCount === 1) {
                            this.showAdditionalAlert('highWarning', `Something went wrong! ${status.failureCount} entity was not ${operation}`);
                        } else {
                            this.showAdditionalAlert('highWarning', `Something went wrong! ${status.failureCount} entities were not ${operation}`);
                        }
                    }
                }
            } else if (status.status === OperationStatus.error) {
                this.showAlert(
                    'danger',
                    `Something went wrong! ${status.failureCount} entities were not ${operation}`,
                );
            }
        }

        private async onActionsButtonClicked(entityId: string): Promise<void> {
            const entity = this.entitiesStatus.list.find((entity) => entity.id === entityId);
            if (!this.submissionLinkPerEntityId.hasOwnProperty(entityId) && entity) {
                this.loadingSubmissionLink = true;
                await this.$store.actions.dashboard.loadEntityRequests({ rpName: this.rpName, entityId });
                if (this.entityRequestsStatus.hasOwnProperty(entityId)
                    && this.entityRequestsStatus[entityId].status === OperationStatus.success) {
                    const entityRequests = [ ...this.entityRequestsStatus[entityId].requests ];
                    entityRequests.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
                    const latestUnsubmitedRequest = entityRequests
                        .find((request) => request.requestId
                            && request.verificationRequestStatus === 'PENDING_INSURED_SUBMISSION');
                    if (latestUnsubmitedRequest) {
                        await this.$store.actions.dashboard.loadEntitySubmissionLink(
                            { rpName: this.rpName, requestId: latestUnsubmitedRequest.requestId });
                    }
                }
                this.loadingSubmissionLink = false;
            }
        }

        private copySubmissionLink(submissionLink: string): void {
            navigator.clipboard.writeText(submissionLink);
            this.$store.dispatch('displaySnackbar', {
                success: true,
                message: `Submission link has been copied to clipboard.`,
            });
        }

        private goToCriterion(riskProfileId: string, requirementType: TprmRequirementType): void {
            const routeData = this.$router.resolve({
                name: 'decisioningRiskProfile',
                params: {
                    rpId: this.$route.params.rpId,
                    id: riskProfileId,
                },
                query: { requirementType },
            });
            window.open(routeData.href, '_blank');
        }

        private finishGrantExceptionProcedure = (modified: boolean, insuredName: string): void => {
            if (!modified) {
                return;
            }
            if (this.grantExceptionStatus === OperationStatus.success) {
                this.showAlert('success', `Exception granted for ${insuredName}`);
            } else {
                this.showAlert('danger', `Failed to grant exception for ${insuredName}`);
            }
        };

        private grantException(entity: Entity): void {
            this.$procedures.execute('grantExceptionProcedure', {
                exception: {
                    rpName: this.rpName,
                    insured: entity,
                    data: [ createEntityLevelException() ],
                },
            }, this.finishGrantExceptionProcedure);
        }

        private finishRemoveExceptionProcedure = (modified: boolean, insuredName: string): void => {
            if (!modified) {
                return;
            }
            if (this.removeExceptionStatus === OperationStatus.success) {
                this.showAlert('success', `Exception removed for ${insuredName}`);
            } else {
                this.showAlert('danger', `Failed to remove exception for ${insuredName}`);
            }
        };

        private removeExceptions(entity: Entity, exceptionIds: string[]): void {
            this.$procedures.execute('removeExceptionProcedure', {
                rpName: this.rpName,
                entity,
                exceptionIds,
            }, this.finishRemoveExceptionProcedure);
        }

        private openHistoricDocumentModal(entity: Entity): void {
            this.$procedures.execute('viewHistoricDocumentProcedure', {
                rpName: this.rpName,
                entity,
                requirementModels: this.getRequirementModelsWithCountryLabel(entity),
            });
        }

        private resetAlertConfig(configName: AlertConfigName, timeoutName: AlertTimeoutName): void {
            clearTimeout(this[timeoutName]);
            this[configName] = null;
        }

        private setupAlertConfig(
            type: AlertType,
            title: string,
            configName: AlertConfigName,
            timeoutName: AlertTimeoutName,
        ): void {
            this.resetAlertConfig(configName, timeoutName);
            this[configName] = { type, title };
            this[timeoutName] = setTimeout(() => this.resetAlertConfig(configName, timeoutName), 5000);
        }

        private hideAlert(): void {
            this.resetAlertConfig('alertConfig', 'alertConfigTimeout');
        }

        private showAlert(type: AlertType, title: string): void {
            this.setupAlertConfig(type, title, 'alertConfig', 'alertConfigTimeout');
        }

        private showAlertInEntityDetails(type: AlertType, title: string): void {
            this.setupAlertConfig(type, title, 'entityDetailsAlertConfig', 'entityDetailsAlertTimeout');
        }

        private showAdditionalAlert(type: AlertType, title: string): void {
            this.setupAlertConfig(type, title, 'additionalAlertConfig', 'additionalAlertConfigTimeout');
        }

        private isEntityNoResponse(entity: Entity) {
            return entity.verificationStatus === VerificationStatus.noInsuredResponse;
        }

        private getRequirementModelsWithCountryLabel(entity: Entity): TprmRequirementModel[] {
            const riskProfileId =
                sortBy(entity.effectiveGroup, [ (riskProfile) => riskProfile.displayName.toLowerCase() ])[0]?.id;
            const group = this.riskProfileStatus.list.find((x) => x.id === riskProfileId);
            const countryCode = group?.countryCode || null;
            return this.requirementModelsStatus.list.map((model) => {
                const label = getRequirementTypeLabelByCountry(
                    model.coverageType, countryCode, this.categorizedEnumLabels,
                );
                return { ...model, label };
            });
        }

        private destroyed(): void {
            this.$store.actions.dashboard.clearEntitiesList({ rpName: this.lastRpName });
            this.$store.actions.dashboard.clearCustomPropertiesList({ rpName: this.lastRpName });
            this.$store.actions.dashboard.clearRequirementTypeList({ rpName: this.lastRpName });
            this.$store.actions.dashboard.clearEntityStatistics({ rpName: this.lastRpName });
            this.$store.actions.dashboardConfiguration.clearDashboardConfiguration({ rpName: this.lastRpName });
            this.$store.actions.entityDetails.clearRequirementDetailsList({ rpName: this.lastRpName });
            this.$store.actions.entityDetails.clearCollateralEntities({ rpName: this.lastRpName });
            clearTimeout(this.alertConfigTimeout);
            clearTimeout(this.errorAlertTimeout);
            clearTimeout(this.additionalAlertConfigTimeout);
            clearTimeout(this.collectEvidenceAlertTimeout);
            clearTimeout(this.entityDetailsAlertTimeout);
        }
    }
</script>
